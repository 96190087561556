.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  top: 45% !important;
}

.ant-spin-text {
  margin-top: 16px !important;
}

.origin-img-box {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  z-index: 10;
}

.origin-img-box-border {
  border-bottom: 6px solid #38bdf8;
}
